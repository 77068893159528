<template>
  <block
    component-name="block-grid-blog"
    body-class="flex flex-col items-stretch gap-4xl min-h-screen"
    ref="tpl_root"
  >
    <template v-slot:default="blockData">
      <client-only v-if="facets">
        <toolbar-filters
          :facets="facets"
          :total="total"
          :use-search="false"
          :use-indoor-outdoor="false"
          class="w-full"
        />
      </client-only>

      <div ref="tpl_grid" class="flex flex-col items-stretch gap-y-4xl w-full">
        <div
          v-for="(row, index) in organizedItems"
          class="w-full"
          :class="{
            'grid grid-cols-1 tablet:grid-cols-12 gap-x-2xl gap-y-4xl':
              !row.highlight,
          }"
        >
          <template v-if="row.highlight === true">
            <card-blog
              :layout="$attrs.config.layout"
              :category="row.category"
              :subcategory="row.subcategory"
              :title="row.title"
              :footer="row.footer"
              :action="row.action"
              :asset="{
                ...row.asset,
                loading:
                  blockData?.loading === 'lazy' || index > 5 ? 'lazy' : 'auto',
              }"
              class="col-span-full w-full"
            />
          </template>

          <template v-else>
            <card-blog
              v-for="(item, i) in row.items"
              :layout="$attrs.config.layout"
              :category="item.category"
              :subcategory="item.subcategory"
              :title="item.title"
              :footer="item.footer"
              :action="item.action"
              :asset="item.asset"
              class="col-span-full tablet:col-span-6 laptop:col-span-4 w-full"
              :class="{
                'laptop:!w-9/12':
                  (row.direction === 'left' && (i === 0 || i === 4)) ||
                  (row.direction === 'right' && (i === 2 || i === 4)),
                'laptop:mx-auto':
                  (row.direction === 'left' && i === 4) ||
                  (row.direction === 'right' && i === 4),
                'laptop:ml-auto': row.direction === 'right' && i === 2,
              }"
            />
          </template>
        </div>
      </div>

      <div
        v-if="loadMoreAvailable"
        class="w-full flex justify-center"
        v-surfer="{
          setup: {
            rootMargin: '0px 0px 2000px 0px',
          },
          observers: {
            enterFromBottom: {
              event: true,
            },
          },
        }"
        @surfer-enter-from-bottom="infiniteScroll"
      >
        <transition-fade>
          <spinner v-if="loading" :spinning="loading" />
        </transition-fade>
      </div>
    </template>
  </block>
</template>

<script setup>
import { useRootStore } from "@/stores/root";

const rootStore = useRootStore();
const attrs = useAttrs();
const { setAppInert } = useGlobals();

const filtersEnabled = ref(attrs.config.endpoint !== undefined);
const loading = ref(false);
const items = ref(filtersEnabled.value ? [] : attrs.data.body.items);
const facets = ref("");
const total = ref(0);
const offset = ref(0);
const itemsPerPage = ref(60);
const route = useRoute();
const tpl_root = templateRef("tpl_root");
const tpl_grid = templateRef("tpl_grid");
const nuxtApp = useNuxtApp();

const loadMoreAvailable = computed(() => {
  return offset.value < total.value;
});

const isTabletOnly = useMediaQuery(
  "(min-width: 1020px) and (max-width: 1271px)"
);

const organizedItems = computed(() => {
  let finalItems;

  // RELATED LAYOUT //
  if (attrs.config.layout === "related") {
    let relatedPack = {
      highlight: false,
      items: [],
      direction: Math.random() > 0.5 ? "left" : "right",
    };

    if (items.value[0]) {
      relatedPack.items.push(items.value[0]);
    }

    if (items.value[1]) {
      relatedPack.items.push(items.value[1]);
    }

    if (items.value[2] && !isTabletOnly.value) {
      relatedPack.items.push(items.value[2]);
    }

    finalItems = [relatedPack];
  }
  // end RELATED LAYOUT //

  // HIGHLIGHTS LAYOUT //
  else if (attrs.config.layout === "highlights") {
    let highlightPack = {
      highlight: false,
      items: [],
      direction: Math.random() > 0.5 ? "left" : "right",
    };

    if (items.value[1]) {
      highlightPack.items.push(items.value[1]);
    }

    if (items.value[2]) {
      highlightPack.items.push(items.value[2]);
    }

    if (items.value[3] && !isTabletOnly.value) {
      highlightPack.items.push(items.value[3]);
    }

    finalItems = [{ ...items.value[0], highlight: true }, highlightPack];
  }
  // end HIGHLIGHTS LAYOUT //

  // DEFAULT LAYOUT //
  else {
    const highlights = items.value.filter((item) => item.highlight);
    const defaults = items.value.filter((item) => !item.highlight);

    let defaultDirection = "left";
    let packs = [];
    let ind = 0;
    let l = defaults.length;

    while (defaults.length) {
      let sixPack = {
        highlight: false,
        items: defaults.splice(ind, 6),
        direction: defaultDirection,
      };

      defaultDirection = defaultDirection === "left" ? "right" : "left";
      packs.push(sixPack);
    }

    const lenghter = Math.max(packs.length, highlights.length);
    finalItems = [];

    for (let i = 0; i < lenghter; i++) {
      if (highlights[i]) finalItems.push(highlights[i]);
      if (packs[i]) finalItems.push(packs[i]);
    }
  }
  // end DEFAULT LAYOUT //

  return finalItems;
});

const updateResults = (data, append = false) => {
  if (append) {
    items.value = items.value.concat(data.results || []);
  } else {
    items.value = data.results || [];
  }

  facets.value = JSON.stringify(data.facets) || "";
  total.value = parseInt(data.total) || 0;
  offset.value = items.value.length;
};

const infiniteScroll = () => {
  // loading.value = true;
  // setTimeout(() => {
  //   loading.value = false;
  // }, 2000);
};

const loadItems = async (query) => {
  let cleanQuery;

  if (query) {
    // clean "referral" query parameter
    cleanQuery = { ...query };
    rootStore.excludeQueryParams.forEach((param) => {
      delete cleanQuery[param];
    });
  }

  const queryToString = (query) => {
    return Object.keys(query)
      .map((key) => `${key}=${query[key]}`)
      .join("&");
  };

  return $fetch(
    `${attrs.config.endpoint}${
      cleanQuery
        ? `?${queryToString({ lng: route.params.locale, ...cleanQuery })}`
        : ""
    }`
  );
};

if (filtersEnabled.value) {
  const data = await loadItems({
    ...route.query,
    items_per_page: itemsPerPage.value,
    offset: offset.value,
  });
  updateResults(data);

  watch(
    () => route.query,
    (val) => {
      nuxtApp.$lenis.scrollTo(tpl_root.value.$el, {
        force: true,
        lock: true,
        duration: 0.3,
        offset: -120,
        easing: gsap.parseEase("power2.out"),
        onComplete: () => {
          ScrollTrigger.refresh(true);
        },
      });

      /*  tl.to(window, {
        duration: 0.3,
        scrollTo: { y: tpl_root.value.$el, offsetY: 120 },
      }); */

      const tl = gsap.timeline();

      tl.to(
        tpl_grid.value,
        {
          duration: 0.3,
          autoAlpha: 0,
          onComplete: () => {
            setAppInert(true);
            loadItems(val).then((data) => {
              setAppInert(false);
              updateResults(data);
              nextTick(() => {
                tl.to(tpl_grid.value, {
                  delay: 0.1,
                  duration: 0.3,
                  autoAlpha: 1,
                });
              });
            });
          },
        },
        0
      );
    }
  );
}
</script>
